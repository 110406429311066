.Header{
    background-color: #24292f;
    height: 50px;
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0;
}

.HeaderInner{
    border: none;
    cursor: pointer;
    padding: 0;
    appearance: none;
    background-color: #24292f;
    display: flex;
    height: 50px;
    z-index: 100;
    text-decoration:none;
}

@media screen and (min-width: 1100px) {
    .HeaderInner{
      margin-left: 10%;
    }
  }

.headder_icon{
    height: 2em;
    border-radius: 50%;
    margin: auto 0.5rem;
}

.title{
    color: #fff;
    font-size: 1.5rem;
    margin: auto 0;
    white-space: nowrap;
}