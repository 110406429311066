.PlofCard{
  display: flex;
  text-align: left;
  flex-direction: row;
  margin: 1rem auto 1.5rem auto;
  background-color: rgb(255, 249, 249);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  max-width: 500px;
}

.me{
  margin: 0 1rem;
  width: 20%;
  animation: buttom 1s ease 1s 1 normal backwards;
}

@media screen and (max-width: 500px) {
  .me{
    width: 25%;
  }
}

@media screen and (max-width: 450px) {
  .me{
    width: 30%;
  }
}

@media screen and (max-width: 380px) {
  .me{
    width: 35%;
  }
}

@media screen and (max-width: 350px) {
  .me{
    width: 40%;
  }
}

@media screen and (max-width: 516px) {
  .PlofCard {
      margin: 1rem 1rem 1.5rem 1rem;
  }
}

.my_data{
  min-width: 0;
  padding-right: 1rem;
  text-align: left;
}

.prolife{
  animation: top 1s ease 1s 1 normal backwards;
}

.my_name{
  margin: 0;
  margin-top:1rem;
}

.ditail{
  padding-left: 1rem;
  border-bottom: solid 1px black;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.follow_me{
  margin: 0;
  animation: right 1s ease 1s 1 normal backwards;
}

.sns{
  margin-left: 1rem;
  animation: right 1s ease 1s 1 normal backwards;
}

.sns_icon{
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}