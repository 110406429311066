.Contents{
  background-color: #f5f5f5;
  padding-top: 100px;
}

.Contents_inner{
  background-color: #fff;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 0 1rem;
}

.page_title{
  max-width: 800px;
  padding: 1rem 1rem;
  background-color: #fff;
  margin: 0 auto;
}

@media screen and (max-width: 1100px) {
  .Contents{
    padding-top: 50px;
  }
}
  
  @media screen and (min-width: 1100px) {
    .Contents_inner {
      margin-bottom: 80px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .page_title {
        padding: 1rem 0;
    }
  }