.next_page{
    margin-left: auto;
    text-decoration:none;
    color: #24292f;
    width: 250px;
    padding: 1rem;
    margin: 1rem;
    background-color: rgb(255, 249, 249);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    border: 0px;
    border-radius: 30px;
}

.next_page:hover{
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
}

.back_page{
    margin-left: auto;
    text-decoration:none;
    color: #24292f;
}

.page_title{
    padding-top: 0.5rem;
    width: 100%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    padding-bottom: 5px;
}

.put_element_in_center{
    display: inline-block;
    vertical-align: middle;
}