.SlideContents{
    background-color: #F0F8FF;
    border-top: solid 0.5px #B0E0E6;
    border-bottom: solid 0.5px #B0E0E6;
}


@keyframes infinity-scroll-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
  
  /*
  for IE11
  ----------------------------*/
  _:-ms-lang(x)::-ms-backdrop,
  .SlideContents {
    display: -ms-grid;
    overflow: hidden;
  }
  /*----------------------------*/
  
  .d-demo__wrap {
    display: flex;
    overflow: hidden;
    padding: 0;
  }
  
  .d-demo__list {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  .d-demo__list--left{
  animation :infinity-scroll-left 60s infinite linear 0.5s both;
  padding: 0;
  }

  .d-demo__list--left_stop{
    animation-play-state: paused;
  }
  
  .d-demo__item > img{
     width: 100%;
  }