.Introduction{
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    text-align: left;
    flex-direction: row;
    margin: 1rem auto 2rem auto;
    background-color: rgb(255, 249, 249);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    max-width: 500px;
}

.card_title_outer{
    margin: 1rem 0 0 1rem;
}

.card_title{
    margin: 1rem;
}

.text{
    margin: 0 1.5rem 1rem 1.5rem;
}

@media screen and (max-width: 516px) {
    .Introduction {
        margin: 1rem 1rem 1.5rem 1rem;
    }
}