.ContentsCard{
    text-align: left;
    flex-direction: row;
    margin: 2rem auto;
    border-radius: 0.1rem;
    background-color: rgb(255, 249, 249);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    max-width: 500px;
}
.ContentsCard:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
}

.contents{
    text-decoration:none;
    color: #000000;
}
.ContentsCard:hover .contents{
    color: rgb(160, 160, 160);
}
.contents_img{
    width: 100%;
    background-color: #e8e8e8;
}
.ContentsCard:hover .contents_img{
    object-view-box: inset(5%);
}
.contents_title{
    margin: 0;
}

.contents_data{
    margin: 0.5rem 1rem;
}

.contents_data_for_slide{
    margin: 0.5rem 1rem;
    height: 80px;
}

.tag_wrapper{
    display: flex;
    margin-left: 0.5em;
    margin-top: 0.5em;
}

.tag{
    border: solid 0.5px black;
    border-radius: 50vmin;
    font-size: small;
    margin: 0.2em;
    padding: 0.2em 0.5em;
}

@media screen and (max-width: 500px) {
    .ContentsCard{
        margin: 1rem auto;
    }
}