.Card{
  text-align: left;
  flex-direction: row;
  margin: 1rem auto;
  background-color: rgb(255, 249, 249);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  max-width: 500px;
}

.card_title{
  margin: 0.5rem;
  margin-bottom: 0.5rem;
  animation: left 1s ease 1s 1 normal backwards;
}

.main_text{
  padding-left: 1rem;
  animation: right 1s ease 1s 1 normal backwards;
}

h3{
  margin: 0px 0px 0.5rem 0px;
}

@keyframes buttom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes top {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes right {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes left {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}